@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: 'IBM Plex Mono';
  font-weight: 400;
  cursor: none;
  max-width: 100%;
  min-height: 100vh;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: hidden;
}

a:hover,
a,
.link:hover {
  cursor: none;
}

.projectBg {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  -webkit-animation: fade 200ms ease-in-out;
  animation: fade 200ms ease-in-out;
}

@-webkit-keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal {
  overflow: hidden;
}
