.cursor-hidden {
  opacity: 0;
}

.cursor {
  height: 26px;
  width: 26px;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  position: absolute;
  pointer-events: none;
  z-index: 9999;
  mix-blend-mode: difference;
  transition: all 100ms ease;
  -moz-transition: all 100ms ease;
  -webkit-transition: all 100ms ease;
  transition-property: opacity, background-color, transform, mix-blend-mode,
    border-right;
  -moz-transition-property: opacity, background-color, transform, mix-blend-mode,
    border-right;
  -webkit-transition-property: opacity, background-color, transform,
    mix-blend-mode, border-right;
  transform: translate(-50%, -50%) rotate(0) scale(1);
  -moz-transform: translate(-50%, -50%) rotate(0) scale(1);
  -webkit-transform: translate(-50%, -50%) rotate(0) scale(1);
}

.cursor-clicked {
  height: 26px;
  width: 26px;
  background-color: rgb(85, 255, 0);
  border-radius: 50%;
  position: absolute;
  pointer-events: none;
  z-index: 9999;
  mix-blend-mode: difference;
  transition: all 75ms ease;
  -moz-transition: all 75ms ease;
  -webkit-transition: all 75ms ease;
  transition-property: opacity, background-color, transform, mix-blend-mode,
    border-right;
  -moz-transition-property: opacity, background-color, transform, mix-blend-mode,
    border-right;
  -webkit-transition-property: opacity, background-color, transform,
    mix-blend-mode, border-right;
  transform: translate(-50%, -50%) rotate(0) scale(1.4);
  -moz-transform: translate(-50%, -50%) rotate(0) scale(1.4);
  -webkit-transform: translate(-50%, -50%) rotate(0) scale(1.4);
}

.cursor-hovered {
  height: 26px;
  width: 26px;
  background-color: rgb(85, 255, 0);
  border-radius: 50%;
  position: absolute;
  pointer-events: none;
  z-index: 9999;
  mix-blend-mode: difference;
  transition: all 100ms ease;
  -moz-transition: all 100ms ease;
  -webkit-transition: all 100ms ease;
  transition-property: opacity, background-color, transform, mix-blend-mode,
    border-right;
  -moz-transition-property: opacity, background-color, transform, mix-blend-mode,
    border-right;
  -webkit-transition-property: opacity, background-color, transform,
    mix-blend-mode, border-right;
  transform: translate(-50%, -50%) rotate(0) scale(1.1);
  -moz-transform: translate(-50%, -50%) rotate(0) scale(1.1);
  -webkit-transform: translate(-50%, -50%) rotate(0) scale(1.1);
}
